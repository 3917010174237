import React from 'react'
import { Button } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import { Link } from "@StarberryUtils"

const ButtonField = ({type, name, value, fieldClass, step,handlechange,handlechange2, videotrue, buttonYes}) => (
	<div className={buttonYes ? `btns d-flex-new  form-group ${step} ` :`btns ${step}  form-group`}>
		{step == 'stepone' &&
			<Button onClick={handlechange}  variant="" type={type} className={fieldClass}>
			{name}
			</Button>
		}
		{step == 'steptwo' &&
			<Button onClick={handlechange2}  variant="" type={type} className={fieldClass}>
			{name}
			</Button>
		}
		{step != 'stepone' && step != 'steptwo' &&
		<Button disabled={videotrue} variant="primary" type={type} className={fieldClass}>
			{name}
		</Button>
		
		}
		                {buttonYes ?
                <Link className="btn new-bt-fix terms-condition" to={'/for-candidates/job-search'}>Start your job search now</Link>
                : null}

	</div>
);

export default ButtonField;